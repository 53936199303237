@import (reference) "../../styles/main";

.SecurityBadges {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0;

  @media @tablet-landscape {
    justify-content: center;
    padding-top: 0.5;
    padding-bottom: 0.5;
  }

  @media @tablet {
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 0;
  }
}
