@import (reference) "../../../styles/main";

@animation-time: 14s;

.Pulse {
  stroke: @color-teal-300;
  stroke-width: 2px;
  stroke-opacity: 1;
  fill: @color-teal-300;
  fill-opacity: 0;

  &.Pulse_0 {
    transform-origin: 105px 83px;
    animation: Pulse_0 @animation-time infinite;
  }
  &.Pulse_1 {
    transform-origin: 168px 135px;
    animation: Pulse_1 @animation-time infinite;
  }
  &.Pulse_2 {
    transform-origin: 168px 337px;
    animation: Pulse_2 @animation-time infinite;
  }
  &.Pulse_3 {
    transform-origin: 305px 115px;
    animation: Pulse_3 @animation-time infinite;
  }
  &.Pulse_5 {
    transform-origin: 375px 180px;
    animation: Pulse_5 @animation-time infinite;
  }
  &.Pulse_6 {
    transform-origin: 430px 185px;
    animation: Pulse_6 @animation-time infinite;
  }
  &.Pulse_7 {
    transform-origin: 475px 240px;
    animation: Pulse_7 @animation-time infinite;
  }
  &.Pulse_8 {
    transform-origin: 526px 283px;
    animation: Pulse_8 @animation-time infinite;
  }
  &.Pulse_9 {
    transform-origin: 276px 130px;
    animation: Pulse_9 @animation-time infinite;
  }
  &.Pulse_10 {
    transform-origin: 296px 140px;
    animation: Pulse_10 @animation-time infinite;
  }
  &.Pulse_11 {
    transform-origin: 526px 153px;
    animation: Pulse_11 @animation-time infinite;
  }
  &.Pulse_12 {
    transform-origin: 586px 333px;
    animation: Pulse_12 @animation-time infinite;
  }
  &.Pulse_13 {
    transform-origin: 336px 130px;
    animation: Pulse_13 @animation-time infinite;
  }
  &.Pulse_14 {
    transform-origin: 475px 210px;
    animation: Pulse_14 @animation-time infinite;
  }
  &.Pulse_15 {
    transform-origin: 500px 235px;
    animation: Pulse_15 @animation-time infinite;
  }
}

.Earth_mask_signal {
  animation: signal_moving @animation-time linear infinite;
}
.Earth_point_0 {
  animation: point_appearing_0 @animation-time linear infinite;
}
.Earth_point_1 {
  animation: point_appearing_1 @animation-time linear infinite;
}
.Earth_point_2 {
  animation: point_appearing_2 @animation-time linear infinite;
}
.Earth_point_3 {
  animation: point_appearing_3 @animation-time linear infinite;
}
.Earth_point_5 {
  animation: point_appearing_5 @animation-time linear infinite;
}
.Earth_point_6 {
  animation: point_appearing_6 @animation-time linear infinite;
}
.Earth_point_7 {
  animation: point_appearing_7 @animation-time linear infinite;
}
.Earth_point_8 {
  animation: point_appearing_8 @animation-time linear infinite;
}
.Earth_point_9 {
  animation: point_appearing_9 @animation-time linear infinite;
}
.Earth_point_10 {
  animation: point_appearing_10 @animation-time linear infinite;
}
.Earth_point_11 {
  animation: point_appearing_11 @animation-time linear infinite;
}
.Earth_point_12 {
  animation: point_appearing_12 @animation-time linear infinite;
}
.Earth_point_13 {
  animation: point_appearing_13 @animation-time linear infinite;
}
.Earth_point_14 {
  animation: point_appearing_14 @animation-time linear infinite;
}
.Earth_point_15 {
  animation: point_appearing_15 @animation-time linear infinite;
}

@keyframes signal_moving {
  0%,
  5% {
    transform: translateX(0);
    opacity: 0;
  }
  10%,
  45% {
    opacity: 1;
  }
  50%,
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes point_appearing_0 {
  0%,
  12.5% {
    opacity: 0;
  }
  14.5%,
  16.5% {
    opacity: 1;
    fill: #fff;
  }
  18.5%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_0 {
  0%,
  18.5% {
    opacity: 0;
  }

  19.5% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  33.5% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  34.5% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  35.5% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  49.5% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  50.5% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  51.5% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  65.5% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  66.5% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  67.5% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  81.5% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  82.5% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  83.5% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  97.5% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  98.5%,
  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_1 {
  0%,
  17% {
    opacity: 0;
  }
  19%,
  21% {
    opacity: 1;
    fill: #fff;
  }
  23%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_1 {
  0%,
  23% {
    opacity: 0;
  }

  24% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  38% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  39% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  40% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  54% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  55% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  56% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  70% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  71% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  72% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  86% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  87% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  88% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  102% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_2 {
  0%,
  17% {
    opacity: 0;
  }
  19%,
  21% {
    opacity: 1;
    fill: #fff;
  }
  23%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_2 {
  0%,
  23% {
    opacity: 0;
  }

  24% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  38% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  39% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  40% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  54% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  55% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  56% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  70% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  71% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  72% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  86% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  87% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  88% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  102% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_3 {
  0%,
  28% {
    opacity: 0;
  }
  30%,
  32% {
    opacity: 1;
    fill: #fff;
  }
  34%,
  97% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_3 {
  0%,
  34% {
    opacity: 0;
  }

  35% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  49% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  50% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  51% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  65% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  66% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  67% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  81% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  82% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  83% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  97% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  98% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  99%,
  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_5 {
  0%,
  33% {
    opacity: 0;
  }
  35%,
  37% {
    opacity: 1;
    fill: #fff;
  }
  39%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_5 {
  0%,
  39% {
    opacity: 0;
  }

  40% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  54% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  55% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  56% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  70% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  71% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  72% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  86% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  87% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  88% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  102% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_6 {
  0%,
  37% {
    opacity: 0;
  }
  39%,
  41% {
    opacity: 1;
    fill: #fff;
  }
  43%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_6 {
  0%,
  43% {
    opacity: 0;
  }

  44% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  58% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  59% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  60% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  74% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  75% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  76% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  90% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  91% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  92% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  106% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_7 {
  0%,
  41% {
    opacity: 0;
  }
  43%,
  45% {
    opacity: 1;
    fill: #fff;
  }
  47%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_7 {
  0%,
  47% {
    opacity: 0;
  }

  48% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  62% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  63% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  64% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  78% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  79% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  80% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  94% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  95% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  96% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  110% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_8 {
  0%,
  44% {
    opacity: 0;
  }
  46%,
  48% {
    opacity: 1;
    fill: #fff;
  }
  50%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_8 {
  0%,
  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  65% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  66% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  67% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  81% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  82% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  83% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  97% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_9 {
  0%,
  26% {
    opacity: 0;
  }
  28%,
  30% {
    opacity: 1;
    fill: #fff;
  }
  32%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_9 {
  0%,
  32% {
    opacity: 0;
  }

  33% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  47% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  48% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  49% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  63% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  64% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  65% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  79% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  80% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  81% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  95% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  96% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  97%,
  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_10 {
  0%,
  27% {
    opacity: 0;
  }
  29%,
  31% {
    opacity: 1;
    fill: #fff;
  }
  33%,
  96% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_10 {
  0%,
  33% {
    opacity: 0;
  }

  34% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  48% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  49% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  50% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  64% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  65% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  66% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  80% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  81% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  82% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  96% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  97% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  98%,
  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_11 {
  0%,
  44% {
    opacity: 0;
  }
  46%,
  48% {
    opacity: 1;
    fill: #fff;
  }
  50%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_11 {
  0%,
  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  65% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  66% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  67% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  81% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  82% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  83% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  97% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_12 {
  0%,
  49% {
    opacity: 0;
  }
  53%,
  55% {
    opacity: 1;
    fill: #fff;
  }
  57%,
  98% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_12 {
  0%,
  55% {
    opacity: 0;
  }

  56% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  70% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  71% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  72% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  86% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  87% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  88%,
  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_13 {
  0%,
  30% {
    opacity: 0;
  }
  32%,
  34% {
    opacity: 1;
    fill: #fff;
  }
  36%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_13 {
  0%,
  36% {
    opacity: 0;
  }

  37% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  51% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  52% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  53% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  67% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  68% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  69% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  83% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  84% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  85% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  98% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  99% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_14 {
  0%,
  41% {
    opacity: 0;
  }
  43%,
  45% {
    opacity: 1;
    fill: #fff;
  }
  47%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_14 {
  0%,
  47% {
    opacity: 0;
  }

  48% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  62% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  63% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  64% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  78% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  79% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  80% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  94% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  95% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  96% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  110% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}

@keyframes point_appearing_15 {
  0%,
  42% {
    opacity: 0;
  }
  44%,
  46% {
    opacity: 1;
    fill: #fff;
  }
  48%,
  95% {
    opacity: 1;
    fill: #78f5de;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Pulse_15 {
  0%,
  48% {
    opacity: 0;
  }

  49% {
    opacity: 1;
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  63% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  64% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  65% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  79% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  80% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  81% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  95% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
  96% {
    opacity: 1;
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(0.3);
  }

  97% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  111% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 0;
  }
}
