@import (reference) "../../styles/main";

.BottomCta {
  width: 100%;
  display: flex;
  text-align: left;
  gap: 6rem;

  @media @tablet-landscape {
    flex-direction: column;
    gap: 4rem;
    text-align: center;
  }
}

.lestUsSectionColor {
  color: rgba(209, 206, 219, 1) !important;
  margin-top: 5%;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  width: 570px;

  @media (min-width: 1025px) {
    width: 579px;
    height: 65px;
  }

  @media @tablet {
    margin-top: 2%;
    width: auto;
    height: auto;
  }

  @media @mobile {
    margin-top: 5%;
    width: auto;
    height: auto;
  }
}

.letUsText {
  height: 48px;
  width: 272px;
}

.divReadyToGetStarted {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media @mobile {
    flex-direction: column;
  }

  @media @tablet {
    flex-direction: column;
  }
}

.letUsButton {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  margin-top: 10%;

  @media @tablet {
    margin-top: 2%
  }

  @media @mobile {
    margin-top: 10%
  }
}

.BottomCtaCol {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  flex: 1 1 0px;

  &:last-child {
    flex: 0 0 36rem;
    justify-content: flex-end;
  }

  > div {
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  @media @tablet-landscape {
    &,
    &:last-child {
      flex: 1 1 auto;
      width: 100%;
    }

    > div {
      align-items: center;
    }
  }

  .BottomCtaList {
    flex-direction: row;
    gap: 2rem;

    .BottomCtaListItem {
      flex-direction: column;
    }

    @media @tablet-landscape {
      flex-direction: column;
      gap: 4rem;
    }
  }
}
