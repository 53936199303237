@import (reference) "../../styles/main";

@backgroundSet: {
  blue_800: @color-blue-800;
  blue_900: @color-blue-900;
  blue_1000: @color-blue-1000;
  violet_1000: @color-violet-1000;
};

.Badge {
  color: @color-violet-100;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0.5rem 0;
  background: @color-coolGray-400;

  each(@backgroundSet, {
    &.@{key} { background-color: @value;}
  });

  svg {
    height: 1rem;
    width: 1rem;
  }
  > div {
    max-height: 1rem;
  }
  .BadgeLabel {
    font-size: 0.875rem;
    line-height: 1;
    margin: 0;
    font-family: @font-family-headings;
    display: inline-flex;
    gap: 0.25rem;
    strong {
      font-weight: 600;
    }
  }

  @media @tablet-landscape {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
